<template>
  <div class="container">
    <br>
    <el-table
      v-loading="loading"
      :data="srrc"
      border
      fit
      style="width: 50%"
    >
      <el-table-column
        :label="$t('header.menu.srrc')"
        prop="label"
      />
      <el-table-column
        width="100"
      >
        <template slot-scope="scope">
          <el-link :href="scope.row.attachmentHost+'/'+scope.row.url">{{ $t('body.download') }}</el-link>
        </template>
      </el-table-column>
    </el-table><br>
    <el-table
      v-loading="loading"
      :data="nal"
      border
      fit
      style="width: 50%"
    >
      <el-table-column
        :label="$t('header.menu.nal')"
        prop="label"
      />
      <el-table-column
        width="100"
      >
        <template slot-scope="scope">
          <el-link :href="scope.row.attachmentHost+'/'+scope.row.url">{{ $t('body.download') }}</el-link>
        </template>
      </el-table-column>
    </el-table><br>
    <el-table
      v-loading="loading"
      :data="ccc"
      border
      fit
      style="width: 50%"
    >
      <el-table-column
        :label="$t('header.menu.ccc')"
        prop="label"
      />
      <el-table-column
        width="100"
      >
        <template slot-scope="scope">
          <el-link :href="scope.row.attachmentHost+'/'+scope.row.url">{{ $t('body.download') }}</el-link>
        </template>
      </el-table-column>
    </el-table><br>
    <el-table
      v-loading="loading"
      :data="nmpa"
      border
      fit
      style="width: 50%"
    >
      <el-table-column
        :label="$t('header.menu.nmpa')"
        prop="label"
      />
      <el-table-column
        width="100"
      >
        <template slot-scope="scope">
          <el-link :href="scope.row.attachmentHost+'/'+scope.row.url">{{ $t('body.download') }}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="alert alert-info" role="alert">
      <strong>NOTE！</strong> {{ $t('body.download_note') }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { getAttachments } from '@/api/csp'
export default {
  name: 'Download',
  data() {
    return {
      loading: false,
      srrc: [],
      nal: [],
      ccc: [],
      nmpa: []
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getAttachments().then(resp => {
        if (resp.status) {
          _.forEach(resp.data, (item) => {
            if (item.type === 'SRRC') {
              this.srrc.push(item)
            } else if (item.type === 'NAL') {
              this.nal.push(item)
            } else if (item.type === 'CCC') {
              this.ccc.push(item)
            } else if (item.type === 'NMPA') {
              this.nmpa.push(item)
            }
          })
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
